import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, LinearProgress, Paper, Stack, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { isLead } from '../../components/auth/userAuth'
import { NodataFound } from '../NodataFound'
import axios from '../../api/axios'
import { Cancel } from '@mui/icons-material'
import { GoalForm } from './Forms/GoalForm'
import Notify from '../../features/helpers/notifyConfig'
import { currentUser, permAllowed, showRoute, specificpermCheck } from "../../Router"
import UserIcon from '@mui/material/Avatar';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { GoalView } from './Forms/GoalView'
import { advancedDate, postData } from '../Events'
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { ContributionForm } from './Forms/ContributionForm'
import InfoIcon from '@mui/icons-material/Info';

const SixMonthsPriorComponent = (dateString) => {
    const date = new Date(dateString);
    const newDate = new Date(date);
    newDate.setMonth(newDate.getMonth() + 6);
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    const formattedDate = newDate.toLocaleDateString('en-GB', options);
    return formattedDate;
}

export const UserGoals = () => {
    const [data, setData] = useState(null);
    const [appraisalData, setappraisalData] = useState(null);
    const [empList, setEmpList] = useState(null);
    const [load, setLoad] = useState(false);
    const [open, setOpen] = useState(false);
    const [openContribution, setOpenContribution] = useState(false);
    const [empGoal, setEmpGoal] = useState(null);
    const [goals, openGoals] = useState(false);
    const [finData, setFinData] = useState({
        "emp_id": "",
        "goals": []
    });
    const { pAppraisal, user_role_string_id, user_id } = currentUser();
    const [alignment, setAlignment] = useState('goal');
    const [contribution, setContribution] = useState({});

    const handleClose = () => {
        setOpen(false);
        setOpenContribution(false);
        openGoals(false);
    };

    const handleChange = (event, newAlignment) => {
        setAlignment(newAlignment);
    };

    const openGoalWindow = () => {
        setOpen(true);
    }

    const openContributionWindow = () => {
        setOpenContribution(true);
    }

    const EmployeeGoalWindow = (value) => {
        setEmpGoal(value);
        openGoals(true);
    }

    const submitAddForm = async () => {
        await axios.post(`/appraisal/add-goals`,
            JSON.stringify(finData),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    withCredentials: true,
                    mode: 'no-cors',
                    'Authorization': `Bearer ` + localStorage.getItem('access')
                }
            }).then((response) => {
                Notify.success("Goal Added Successfully");
                setFinData({
                    "emp_id": "",
                    "goals": []
                })
                setLoad(true);
                handleClose();
            }).catch((error) => {
                // console.log(error.response);
                Notify.error("Goal Can't Be Added. Try Again!")
            })
    }

    const AddContributions = async () => {
        await axios.post(`/appraisal/post-emp-content-contribution`,
            JSON.stringify({
                "media_contribution": contribution
            }),
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    withCredentials: true,
                    mode: 'no-cors',
                    'Authorization': `Bearer ` + localStorage.getItem('access')
                }
            }).then((response) => {
                Notify.success("Media Added Successfully");
                setLoad(true);
                handleClose();
            }).catch((error) => {
                // console.log(error.response);
                Notify.error("Media Can't Be Added. Try Again!")
            })
    }

    const getContributions = async () => {
        await axios.get("/appraisal/get-emp-content-contribution",
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ` + localStorage.getItem('access')
                }
            }).then((response) => {
                setappraisalData(response?.data);
            }).catch((error) => {
                console.log(error.response)
            })
    }

    const SeeGoalStyle = () => {
        return {
            cursor: 'pointer',
            color: 'grey',
            transition: 'all 0.3s ease-in-out',
            '&:hover': {
                color: 'primary.main',
                transform: 'translateX(5px)',
            }
        }
    }

    const Wrapper = ({ value, addtn, apprStatus="" }) => {
        return (
            <Grid xl={3} lg={3} md={4} item>
                <Paper sx={{ borderRadius: '10px', overflow: 'hidden' }} elevation={2}>
                    <Box position='relative'>
                        <Stack bgcolor={value.emp_id == user_id ? '#880e4f' : '#00695c'} direction='row' justifyContent='center' py={2}>
                            <UserIcon variant='rounded' sx={{ width: '60px', height: '60px', border: 'solid 2px white', transform: 'translateY(40px)' }} />
                        </Stack>
                        <Box px={2} pt={4} pb={5} textAlign='center'>
                            {empList
                                ? Object.keys(empList).map((key, val) => {
                                    if (empList[key].emp_uuid == value.emp_id) {
                                        return (
                                            <Typography variant='h6' fontFamily='Segoe UI' fontWeight='700'>{value.emp_id == user_id ? "You" : empList[key].employee_name}</Typography>
                                        )
                                    };
                                })
                                : ""}
                            <Stack onClick={() => EmployeeGoalWindow(value)} direction='row' justifyContent='center' alignItems='center' className='GoalAnime' sx={SeeGoalStyle}>
                                <Typography fontWeight='500' variant='subtitle1' fontFamily='Segoe UI'>{value.goals.length} goals added&nbsp;</Typography>
                                <KeyboardDoubleArrowRightIcon fontSize='sm' />
                            </Stack>

                            <Box mt={0.4}>
                                <Typography variant='subtitle2' fontFamily='Segoe UI' fontWeight='400'><i>next evaluation<ArrowRightIcon sx={{ my: '-7px', transform: 'rotateZ(5deg)' }} />{SixMonthsPriorComponent(advancedDate(postData(value.created_at)))}</i></Typography>
                            </Box>

                            {
                            apprStatus !=""
                            ?<Stack direction='row' alignItems='center' margin='auto' spacing={0.5} position='absolute' bottom={7} textAlign='center' sx={{backgroundColor: '#29b6f6'}} borderRadius={0.8} px={1} py={0.2}>
                                <InfoIcon fontSize='0.7rem' sx={{color: 'white'}}/>
                                <Typography fontSize='0.7rem' color='white'>Your Contributions are yet to be confirmed</Typography>
                            </Stack>
                            :<></>
                            }

                        </Box>
                    </Box>
                </Paper>
            </Grid>
        )
    }

    const getGoals = async () => {
        await axios.get("/appraisal/get-goal",
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ` + localStorage.getItem('access')
                }
            }).then((response) => {
                // console.log(response.data.message ?? response.data);
                setData(response.data.message ?? response.data);
            }).catch((error) => {
                // console.log(error.response)
            })
    }

    const getEmployees = async () => {
        await axios.get("/blog-tracking/get-all-employees",
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ` + localStorage.getItem('access'),
                    'Access-Control-Allow-Origin': '*',
                    mode: 'no-cors'
                }
            }).then((response) => {
                // console.log(response);
                setEmpList(response?.data?.message);
            }).catch((error) => {
                // console.log(error.response)
            })
    }

    useEffect(() => {
        getEmployees();
        getGoals();
        getContributions();
    }, [])

    useEffect(() => {
        if (load) {
            getGoals();
            setLoad(false);
        }
    }, [load])

    return (
        <Box py={2} px={3}>
            <Stack justifyContent='space-between' direction='row'>
                <Typography variant='h5' fontWeight={600}>Goals and Contributions</Typography>
                <Stack direction='row' spacing={2}>
                    <Button onClick={openContributionWindow} variant='contained' color='success'>Add Contribution</Button>
                    {showRoute((specificpermCheck(pAppraisal, ['Add Goals'])), <Button onClick={openGoalWindow} variant='contained'>Add Team Goals</Button>, <></>)}
                </Stack>
            </Stack>

            <Box mt={3}>
                {
                    data && appraisalData
                        ? (data == "No documents found"
                            ? <NodataFound msg="No goals set. Discuss with your lead first !" />
                            : (
                                <Grid container spacing={5}>
                                    {data.your_entries.map((key, ind) => {
                                        return (
                                            <Wrapper addtn={ind} value={key} apprStatus={appraisalData.your_content_entries[0]} />
                                        )
                                    })}

                                    {data.managed_entries.map((key, ind) => {
                                        return (
                                            <Wrapper addtn={ind} value={key} />
                                        )
                                    })}
                                </Grid>

                                // data.your_entries.map((key, ind) => {
                                //     return (<Wrapper addtn={ind} value={key} />)
                                // })
                            )
                        )
                        : <Stack justifyContent="center" alignItems="center" height="70vh">
                            <Box pb={2}>Wait while we gather required information...</Box>
                            <Stack gap={4} sx={{ width: '50%', color: 'grey.500' }} spacing={2}>
                                <LinearProgress color="inherit" />
                            </Stack>
                        </Stack>
                }
            </Box>

            {/* Set Goals box for team lead only */}
            <Dialog
                fullWidth
                maxWidth="xs"
                open={open}
                onClose={handleClose}
                aria-labelledby="edit-apartment"
            >
                {/* <DialogTitle id="edit-apartment"> */}

                <Box sx={{ background: 'url("./assets/goal-set.jpg") gray', margin: '12px', borderRadius: '10px', boxShadow: 'inset 1px 1px 25px 10px #d3d3d387', backgroundSize: 'cover', backgroundPosition: 'center', padding: '1.5rem 1rem 1.5rem' }}>
                    <Typography variant='h5' fontFamily='Segoe UI' color='white' fontWeight='700'>Set New Goal</Typography>
                    <Typography mt={0.5} color='white' fontFamily='Segoe UI' sx variant='subtitle2' fontWeight='400' lineHeight={1.2}>You can assign upto 5 goals to a team member</Typography>
                </Box>
                {/* </DialogTitle> */}
                <DialogContent sx={{ padding: '14px' }}>
                    <GoalForm empList={empList} finData={finData} setFinData={setFinData} />
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' onClick={handleClose} sx={{ px: 5 }} color="error">
                        Close
                    </Button>
                    <Button variant='contained' onClick={submitAddForm} sx={{ px: 6 }} color="primary">
                        Set
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Set your own contributions box */}
            <Dialog
                fullWidth
                maxWidth="xs"
                open={openContribution}
                onClose={handleClose}
                aria-labelledby="edit-apartment"
            >
                <Box sx={{ backgroundColor: '#C99B6A', margin: '12px', borderRadius: '10px', padding: '1.5rem 1rem 1.5rem' }}>
                    <Typography variant='h5' fontFamily='Segoe UI' color='white' fontWeight='700'>Set Your Contributions</Typography>
                    <Typography mt={0.5} color='white' fontFamily='Segoe UI' sx variant='subtitle2' fontWeight='400' lineHeight={1.2}>Contribution includes blogs, videos, ppts etc you made till now. You can come anytime and increase/decrease the count of your contributions accordingly!</Typography>
                </Box>
                <DialogContent sx={{ padding: '14px' }}>
                    <ContributionForm setContribution={setContribution} contribution={contribution} />
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' onClick={handleClose} sx={{ px: 5 }} color="error">
                        Close
                    </Button>
                    <Button variant='contained' onClick={AddContributions} sx={{ px: 6 }} color="primary">
                        Set
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Open Individual's goal window */}
            {(empList && empGoal)
                ? <Dialog
                    fullWidth
                    maxWidth="lg"
                    open={goals}
                    onClose={handleClose}
                    aria-labelledby="edit-apartment"
                >
                    <DialogTitle id="edit-apartment" sx={{ bgcolor: '#e9e9e9' }}>
                        <Typography variant='h5' fontFamily='Segoe UI' fontWeight='700' mb={0.3}>Goal Details of&nbsp;
                            {Object.keys(empList).map((key, val) => {
                                return (empList[key].emp_uuid == empGoal.emp_id) ? empList[key].employee_name : ""
                            })}
                        </Typography>
                        {/* <Typography fontFamily='Segoe UI' sx variant='subtitle2' fontWeight='400'>Provide status to this particular goal based on employee's progress on respective goal!</Typography> */}
                    </DialogTitle>
                    <DialogContent sx={{ bgcolor: '#e9e9e9', borderTop: 'solid 1px lightgrey' }}>
                        {empGoal
                            ? <GoalView load={load} setLoad={setLoad} empList={empList} value={empGoal} user_id={user_id} />
                            : <></>
                        }
                    </DialogContent>
                    <Button sx={{ position: "absolute", top: 14, right: 2 }} onClick={handleClose} color="error">
                        <Cancel sx={{ fontSize: "1.6rem" }} />
                    </Button>
                </Dialog>
                :
                <></>
            }
        </Box>
    )
}
